<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <!-- <div class="my-order01">
          <span>首页</span>
        </div> -->
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="info" />
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right">
            <div class="one">{{ $t('user.info') }}</div>
            <div class="two">
              <div>
                <span>{{ $t('user.info.en') }}:</span><br />
                <input type="text" v-model="list.companyNameEn" />
              </div>
              <div>
                <span>{{ $t('user.info.zh') }}:</span><br />
                <input type="text" v-model="list.companyName" />
              </div>
              <div>
                <span>{{ $t('user.info.area') }}:</span><br />
                <a-select
                    style="width: 300px"
                    @change="handleChange"
                    v-model="list.countryId"
                >
                    <a-select-option :value='0'>{{ $t('user.info.select-country') }}</a-select-option>
                    <a-select-option :value="item.countryId" v-for="(item,index) in countryList" :key="index">
                        {{item.countryName}}
                    </a-select-option>
                </a-select>
              </div>
              <!-- <div>
                <span>经营模式:</span>&nbsp;
                <input type="text" v-model="list.companyName" />
              </div> -->
              <div>
                <span>{{ $t('user.info.address-zh') }}:</span><br />
                <input type="text" v-model="list.address" />
              </div>
              <div>
                <span>{{ $t('user.info.address-en') }}:</span><br />
                <input type="text" v-model="list.addressEn" />
              </div>
              <div>
                <span>{{ $t('user.info.company-tel') }}:</span><br />
                <input type="text" v-model="list.companyPhone" />
              </div>
              <div>
                <span>{{ $t('user.info.website') }}:</span> <br>
                <input type="text" v-model="list.website" />
              </div>
               <div>
                <span>{{ $t('user.info.contact-tel') }}:</span> <br>
                <input type="text" v-model="list.flPhone" />
              </div>
               <div>
                <span>{{ $t('user.info.fax') }}:</span> <br>
                <input type="text" v-model="list.fax" />
              </div>
              <!-- <div>
                <span>时区:</span>&nbsp;
                <span>+8</span>
              </div> -->
            </div>
            <div class="one">{{ $t('user.info.contact') }}</div>
            <div class="three">
              <div>
                <span>{{ $t('user.info.first-name') }}:</span><br />
                <input type="text" v-model="list.firstName" />
              </div>
              <div>
                <span>{{ $t('user.info.last-name') }}:</span><br />
                <input type="text" v-model="list.lastName" />
              </div>
              <div>
                <span>{{ $t('user.info.chinese-name') }}:</span><br />
                <input type="text" v-model="list.chName" />
              </div>
              <div>
                <span>{{ $t('user.info.gender') }}:</span>
                <a-radio-group v-model="list.sex" @change="onChange" style="margin-left: 0">
                  <a-radio :value="0">
                    {{ $t('user.info.gender-0') }}
                  </a-radio>
                  <a-radio :value="1">
                    {{ $t('user.info.gender-1') }}
                  </a-radio>
                  <a-radio :value="2">
                    {{ $t('user.info.gender-2') }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div>
                <span>{{ $t('user.info.tel') }}:</span> <br>
                <input type="text" v-model="list.phone" />
              </div>
              <div>
                <span>{{ $t('user.info.position') }}:</span> <br>
                <input type="text" v-model="list.position" />
              </div>
              <div>
                <span>{{ $t('user.info.email') }}:</span> <br>
                <input type="text" v-model="list.email" />
              </div>
              <div>
                <span>{{ $t('user.info.wechat') }}:</span> <br>
                <input type="text" v-model="list.wechatAccount" />
              </div>
            </div>
            <div class="one">{{ $t('user.info.business') }}</div>
            <div class="four">{{ $t('user.info.main') }}:</div>
            <div class="four"><input type="text" v-model="list.mainProducts" /></div>
            <div class="four">{{ $t('user.info.desc-zh') }}:</div>
            <div class="five">
                <textarea  v-model="list.companyDesc">
                </textarea>
            </div>
            <div class="four">{{ $t('user.info.desc-en') }}:</div>
            <div class="five">
                <textarea  v-model="list.companyDescEn">
                </textarea>
            </div>
            <div class="four">{{ $t('user.info.qualification') }}:</div>
            <div class="six">
              <a-upload
                  name='file'
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="uploadImageFile"
                  :data="{ field: 'images' }"
              >
                  <a-button :loading="imagesUploadStatus"> <a-icon type="upload" /> {{ $t('user.info.qualification-upload') }} </a-button>
              </a-upload>
              
            </div>
            <div style="display:flex">
              <div v-for="(item,index) in list.images" :key="index" style="display:inline-block;width:180px;height:180px;position:relative">
                <img :src="item" alt="">
                <a-icon type="close" style="position:absolute;top:0;right:0" @click="viedoRemove(index)"/>
              </div>
            </div>
            <div class="four">{{ $t('user.info.company-logo') }}:</div>
            <div class="six">
                <a-upload
                    name='file'
                    list-type="picture-card"
                    :show-upload-list="false"
                    :customRequest="uploadImageFile"
                    :data="{ field: 'logo' }"
                >
                  <a-button :loading="logoUploadStatus"> <a-icon type="upload" /> {{ $t('user.info.company-logo-upload') }} </a-button>
                </a-upload>
                <img :src="list.logo" alt="" v-if="list.logo">
            </div>
            <div class="four">{{ $t('user.info.company-img') }}:</div>
            <div class="six">
                <a-upload
                    name='file'
                    list-type="picture-card"
                    :show-upload-list="false"
                    :customRequest="uploadImageFile"
                    :data="{ field: 'companyThumb' }"
                >
                  <a-button :loading="companyThumbUploadStatus"> <a-icon type="upload" /> {{ $t('user.info.company-img-upload') }} </a-button>
                </a-upload>
                <img :src="list.companyThumb" alt="" v-if="list.companyThumb">
            </div>
            <div class="four">{{ $t('user.info.index-banner') }}:</div>
            <div class="six">
                <a-upload
                    name='file'
                    list-type="picture-card"
                    :show-upload-list="false"
                    :customRequest="uploadImageFile"
                    :data="{ field: 'indexThumb' }"
                >
                    <a-button :loading="indexThumbUploadStatus"> <a-icon type="upload" /> {{ $t('user.info.index-banner-upload') }} </a-button>
                </a-upload>
                <img :src="list.indexThumb" alt="" v-if="list.indexThumb">
            </div>
            <div class="four">{{ $t('user.info.company-video') }}:</div>
            <div class="seven">
                <a-upload
                    name='file'
                    list-type="picture-card"
                    :show-upload-list="false"
                    :customRequest="uploadVideoFile"
                    :data="{ field: 'videoUrl' }"
                >
                    <a-button :loading="videoUrlUploadStatus"> <a-icon type="upload" /> {{ $t('user.info.company-video-upload') }} </a-button>
                </a-upload>
              <div style="width:300px;height:180px">
                <video :src="list.videoUrl" controls="controls" class="video"></video>
              </div>
            </div>
            <!-- <div class="four">企业VR:</div>
            <div class="four">
              <a href="http://www.baidu.com">http://www.baidu.com</a>
            </div> -->
            <div class="four">{{ $t('user.info.pro-category') }}:</div>
            <div class="eight">
               <a-checkbox-group v-model="list.companyCategoryIds">
                    <a-checkbox :value="item.categoryId" v-for="(item,index) in cateList" :key="index">
                        {{item.categoryName}}
                    </a-checkbox>
                </a-checkbox-group>
            </div>
            <div class="eight">
              <a-button type="primary" @click="submit()">{{ $t('user.save') }}</a-button>
            </div>
          </a-card>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
   </div>
</template>

<style>
  input[type=text]{
      width: 300px;
      border: 1px solid #787878;
  }
  .three input[type=text]{
      width: 200px;
  }
  textarea{
      width: 100%;
      height: 180px;
      resize: none;
  }
  .one{
    font-size: 24px;
    margin: 20px 0 0 20px;
    text-align: left;
  }
  .ant-card-body>.one:nth-child(1){
    margin-top: 0;
  }
  .two,.three{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
  }
  .two>div{
    width: 47%;
    text-align: left;
    margin: 10px 0 10px 20px;
  }
  .three>div{
    width: 30%;
    text-align: left;
    margin: 10px 0 10px 20px;
  }
  .four{
    text-align: left;
    margin: 20px 0 0 20px;
  }
  .five{
    margin: 20px 0 0 20px;
    text-align: left;
  }
  .six{
    width: 200px;
    height: 200px;
    text-align: left;
    margin: 20px 0 0 20px;  
    display: flex;
  }
  .six>img{
    margin-left: 10px;
  }
  .seven{
    width: 320px;
    height: 180px;
    text-align: left;
    margin: 20px 0 0 20px;
    display: flex;
  }
  .seven video{
    width: 100% !important;
    height: 100% !important;
  }
  .eight{
    text-align: left;
    margin: 20px 0 0 20px;
  }
  .ant-upload.ant-upload-select-picture-card{
      width: 200px;
      height: 200px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper{
      margin-left: 0;
  }
  .ant-radio-group {
    margin-left: 10px;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'
export default {
  name: 'UsersIndex',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        info: {

        },
        value: 1,
        radioStyle: {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        },
        list: [],
        liaisons: [],
        countryId: '',
        countryList: [],
        videoUrl: '',
        mediasUploadStatus: false,
        cateList: [],
        userId: this.$route.params.userId,
        videoUrlUploadStatus: false,
        logoUploadStatus: false,
        companyThumbUploadStatus: false,
        indexThumbUploadStatus: false,
        imagesUploadStatus: false
    }
  },
  mounted () {
    this.getList()
    this.getCountryList()
    this.getCateList()
  },
  methods: {
     onChange(e) {
      this.list.sex = e.target.value
    },
     // 获取账号信息
    getList () {
       this.$common.fetchList('/user/account/info', {}).then(data => {
        if (data.code === 200) {
          const result = data.datas
          this.list = result
          this.list.sex = this.list.sex ? parseInt(this.list.sex) : 0
        }
      }).catch(() => {
        this.$notification.error({
            message: '错误',
            description: '请求信息失败，请重试'
        })
      })
    },
    handleChange(value) {
      console.log(value); // { key: "lucy", label: "Lucy (101)" }
      this.countryId = value
    },
    //获取国家列表
    getCountryList () {
       this.$common.fetchList('/user/country/lists', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.countryList = result
        }
      }).catch(() => {
        this.$notification.error({
            message: '错误',
            description: '请求信息失败，请重试'
        })
      })
    },
     // 上传图片
    uploadImageFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadImageFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          if (field === 'images') {
            this.list[field].push(result.url)
          } else if(field === 'indexThumb') {
             this.list[field] = result.url
          } else {
            this.list[field] = result.url
          }
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
    //上传视频
    uploadVideoFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadVideoFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          this.list[field] = result.url
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
     // 图片预览
    videoPreview (url) {
      this.videoUrl = url
      this.previewVisible = true
    },
    // 图片删除
    viedoRemove (index) {
      const len = this.list.images.length
      if (index <= len - 1) {
        this.list.images.splice(index, 1)
      }
    },
     // 获取行业分类
     getCateList () {
       this.$common.fetchList('/exhibition/category/lists?parentId=0', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.cateList = result
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    //提交数据
    submit() {
        console.log(this.list)
        const params = {
            companyName: this.list.companyName,
            companyNameEn: this.list.companyNameEn,
            companyDesc: this.list.companyDesc,
            companyDescEn: this.list.companyDescEn,
            address: this.list.address,
            addressEn: this.list.addressEn,
            logo: this.list.logo,
            countryId: this.list.countryId,
            videoUrl: this.list.videoUrl,
            mainProducts: this.list.mainProducts,
            mainProductsEn: this.list.mainProductsEn,
            tradeTerms: this.list.tradeTerms,
            images: this.list.images,
            categoryIds: this.list.companyCategoryIds,
            companyPhone: this.list.companyPhone,
            indexThumb: this.list.indexThumb,
            companyThumb: this.list.companyThumb,
            website: this.list.website,
            liaisons: {
              firstName: this.list.firstName,
              lastName: this.list.lastName,
              chName: this.list.chName,
              sex: this.list.sex,
              phone: this.list.phone,
              email: this.list.email,
              wechatAccount: this.list.wechatAccount,
              position: this.list.position,
              flPhone: this.list.flPhone,
              fax: this.list.fax
            }
        }
        this.$common.handlePost('/user/account/handle', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'UsersIndex', params: {userId: this.userId}})
        }
        }).catch(() => {
          this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
          })
      })
    }
  }
}
</script>